import React from 'react';
import Helmet from 'react-helmet';
import MetaData from '../utils/MetaData';
import logoFile from "../assets/svg/predictively-logo.svg";

const siteUrl = 'https://www.predictively.com';

const Head = ({ title, path, description, keywords, image }) => {
  const url = `${siteUrl}${path}`;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      link={[
        { rel: 'canonical', href: url },
        {
          rel: 'stylesheet',
          type: 'text/css',
          href:
            'https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500|Poppins:300,500|Roboto:300,400,500,700&display=swap',
        },
      ]}
      meta={MetaData({
        title,
        url,
        description,
        keywords,
        image,
      })}>
    </Helmet>
  );
};

Head.defaultProps = {
  title: 'Predictively',
  path: '/',
  description:
    'Predictively is a suite of AI-powered tools that provide real-time recommendations for website design and branding to help you connect with, convert, and keep your customers.',
  keywords:
    'design, user experience, recommendations, artificial intelligence, AI',
  image: logoFile,
};

export default Head;
