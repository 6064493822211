// @flow

type CSSClassObject = {
  [string]: boolean,
};

export default (cssClassObject: CSSClassObject): string => {
  return Object.keys(cssClassObject)
    .filter((key) => cssClassObject[key])
    .join(" ");
};
