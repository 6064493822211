export default MetaData => {
  const tags = [];
  const prefixes = ['', 'og', 'twitter'];

  Object.keys(MetaData).forEach(tag => {
    const content = MetaData[tag];

    prefixes.forEach(prefix => {
      const isOg = prefix === 'og';
      const field = isOg ? 'property' : 'name';
      const newTag = prefix ? `${prefix}:${tag}` : tag;

      tags.push({
        [field]: newTag,
        content,
      });
    });
  });

  const extraTags = [
    {
      name: 'twitter:creator',
      content: '@codazen',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'google-site-verification',
      content: 'nEwpCXwOE9gguV01dIzJ8UFZ1v7yYf992ixsS3k1xCs',
    },
  ];
  return tags.concat(extraTags);
};
